<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Marketing</a></li>
    </ol>
    <h1 class="page-header">Marketing</h1>
    <panel noButton="true" title="Marketing" variant="panel-default">
      <div class="row">
        <div class="col-md-3">
          <a
              class="btn btn-info btn-sm pull-right"
              href="#"
              @click.prevent="show_new_group = true"
          >New Group</a>
          <h5>Active Groups</h5>
          <table class="table">
            <tr
                v-for="(group,i) in groups"
                :key="i"
            >
              <td>
                <a
                    href="#"
                    @click.prevent="selected_group = group"
                >{{ group.name }}</a>
              </td>
            </tr>
          </table>
        </div>
        <div
            v-if="selected_group.name"
            class="col-md-3"
        >
          <h5>{{ selected_group.name }} members</h5>
          <table class="table">
            <tr
                v-for="(member,i) in selected_group.members"
                :key="i"
            >
              <td>
                {{ member.name }}<a
                  class="pull-right"
                  href="#"
                  @click.prevent="removeMember(member)"
              >x</a>
              </td>
            </tr>
          </table>
          <a
              class="btn btn-info btn-sm pull-right"
              href="#"
              @click.prevent="add_members = true"
          >Add Members</a>
        </div>
        <div
            v-if="!add_members"
            class="col-md-6"
        >
          <form @submit.prevent="sendMessage">
            <div class="form-group">
              <label>Type</label>
              <select
                  v-model="message.type"
                  class="form-control"
              >
                <option value="sms">
                  SMS
                </option>
                <option value="email">
                  Email
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Message</label>
              <textarea
                  v-model="message.message"
                  class="form-control"
                  rows="4"
              />
            </div>
            <div class="form-group">
              <button class="btn btn-primary">
                Send Message
              </button>
            </div>
          </form>
        </div>
        <div
            v-if="add_members"
            class="col-md-6"
        >
          <h5>Companies</h5>
          <a
              class="pull-right"
              href="#"
              @click.prevent="add_members = false"
          >cancel</a>
          <form @submit.prevent="">
            <div class="form-group">
              <label>Search Companies</label>
              <input
                  v-model="search_term"
                  class="form-control"
                  type="text"
              >
            </div>
            <br>
            <ul>
              <li
                  v-for="(company,i) in companies"
                  :key="i"
              >
                <a
                    href="#"
                    @click.prevent="addMember(company)"
                >{{ company.name }}</a>
              </li>
            </ul>
          </form>
        </div>
        <div
            v-if="show_new_group"
            class="col-md-3"
        >
          <div class="card">
            <h5>Create new group</h5>
            <form @submit.prevent="saveNewGroup">
              <div class="form-group">
                <label>Name</label>
                <input
                    v-model="new_group.name"
                    class="form-control"
                    type="text"
                >
              </div>
              <div class="form-group">
                <button class="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>

export default {

  data() {
    return {
      groups: [],
      selected_group: {},
      editor_options: {
        height: '400px',
        file_picker_types: 'image',
      },
      show_new_group: false,
      new_group: {name: ''},
      add_members: false,
      companies: [],
      search_term: '',
      message: {type: '', message: ''}
    }
  },
  mounted() {
    this.fetch();
    this.fetchCompanies();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/list-groups').then(response => {
        this.groups = response.data;
      });
    },

    saveNewGroup() {
      this.$axios.post('/api/v1/create-group', this.new_group).then(response => {
        this.$toastr.s('The group has been created');
        this.selected_group = response.data;
        this.add_members = true;
        this.show_new_group = false;
        this.groups.push(response.data);
      });
    },

    fetchCompanies() {
      this.$axios.get('/api/v1/companies').then(response => {
        this.companies = response.data;
      });
    },

    addMember(company) {
      let data = {
        group_id: this.selected_group.id,
        company_id: company.id
      };

      this.$axios.post('/api/v1/attach-group', data).then(() => {
        this.selected_group.members ? '' : this.selected_group.members = [];
        this.selected_group.members.push(company);
        this.$toastr.s(company.name + ' has been added to ' + this.selected_group.name);
      });
    },

    removeMember(company) {
      let data = {
        group_id: this.selected_group.id,
        company_id: company.id
      };

      this.$axios.post('/api/v1/detach-group', data).then(() => {
        this.$toastr.s(company.name + ' has been removed from ' + this.selected_group.name);
        this.selected_group.members = this.selected_group.members.filter(member => {
          return member.id !== company.id;
        })
      });
    },

    sendMessage() {
      this.message.group_id = this.selected_group.id;
      this.$axios.post('/api/v1/send-message', this.message).then(() => {
        this.$toastr.s('The message has been sent');
      })
    }
  }
}
</script>
